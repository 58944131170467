import 

body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:  #FFF;
    
  }

.oculto{
  display: none;
}

.oculto3{
  /* style={{backgroundImage: "url(" + Background + ")"}} 
  import Background from '../../../assets/images/card-contato.png'*/
  background-image: url(./assets/images/card-contato.png);
}

.missaodiv{
  height: 200px;
}

.prod-home{
  margin-top: '3rem';
  width: '40rem'; 
  background: #FFF;
}

@media (max-width: 991px) {
  .oculto1  {
    display: none;
  }
  .oculto{
    display: block;
  }
}

@media (max-width: 767px) {
  .oculto2  {
    display: none;
  }
}

@media (max-width: 991px) {
  .oculto3  {
    background-image: none;
    background: #FFF;
  }
}

@media (max-width: 1420px) {
  .oculto4  {
    display: none;
  }
}

/* @media (min-width: 991px) {
  .missaodiv{
    height: 290px;
  }
} */

@media (max-width: 767px) {
  .visaodiv{
    margin-top: 4%;
  }
  .missaodiv{
    height: 300px;
  }
}

@media (max-width: 1176px) {
  .missaodiv{
    height: 225px;
  }
}

@media (max-width: 1231px) {
 .prod-home{
  width: 30rem;
  margin-top: '0.1rem';
 }
}

@media (max-width: 1231px) {
  .icon25{
   width: 10rem;
  }
 }

 @media (max-width: 1231px) {
 .descMobi{
    font-size: 1rem;
 }
}

.containerP{
  margin-left: 10%;
}

.title{
  text-align: center;
  margin-top: 2%;
  font-weight: bolder;
  color: '#2E3D62'; 
}
.subtitle{
  text-align: center;
  margin-top: -1%;
  color: '#2E3D62';
}

.blog-details-content {
  padding-top: 20px; }
  .blog-details-content .meta li {
    display: inline-block;
    position: relative; }
    .blog-details-content .meta li + li {
      margin-left: 30px; }
      .blog-details-content .meta li + li::before {
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #ff0000;
        bottom: 4px;
        left: -18px; }
    .blog-details-content .meta li a {
      font-size: 14px;
      font-weight: 700;
      color: #ff0000;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .blog-details-content .meta li a:hover {
        color: #2e3d62; }
  .blog-details-content .title2 {
    font-size: 30px;
    font-weight: 800;
    color: #2e3d62;
    margin-top: 10px; }
    @media (max-width: 767px) {
      .blog-details-content .title2 {
        font-size: 20px; } }
  .blog-details-content p {
    font-size: 18px;
    line-height: 30px;
    color: #666e82;
    font-weight: 400;
    padding-right: 10px;
    margin-top: 25px; }
    @media (max-width: 767px) {
      .blog-details-content p {
        font-size: 16px;
        line-height: 27px; } }
  .blog-details-content .blog-details-meta a {
    margin-right: 14px;
    display: 16px;
    font-weight: 500;
    color: #666e82; }
    .blog-details-content .blog-details-meta a i {
      color: #ff0000;
      padding-right: 5px; }

      .powerful-tools-content .powerful-content-wrapper .content-list {
        padding-top: 35px; }
        .powerful-tools-content .powerful-content-wrapper .content-list li {
          font-size: 18px;
          font-weight: 500;
          color: #666e82;
          margin-top: 10px;
          position: relative;
          padding-left: 25px; }
          @media (max-width: 767px) {
            .powerful-tools-content .powerful-content-wrapper .content-list li {
              font-size: 16px;
              line-height: 28px; } }
          .powerful-tools-content .powerful-content-wrapper .content-list li i {
            color: #ff0000;
            position: absolute;
            top: 5px;
            left: 0; }