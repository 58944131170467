.carousel__dot{
    padding: 7px;
    border-radius: 50px;
    border: 2px #EDD47A solid;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;  
    text-decoration: none;
    margin-right: 10px;

}
.carousel__dot--selected{
    background: #EDD47A;
}