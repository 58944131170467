.sliderBackButton{

    background: white;
    border: 3px solid #4D4D4D;

    :hover {
      background: #EDD47A;
      border-color: #EDD47A; 
    }
  }

.sliderNextButton{
    
    background: white;
    border: 3px solid #4D4D4D;
    margin-left: 60px;

    
}